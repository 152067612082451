<template>
    <div class="support">
 <div class="col-sm-12">

            <div class="card bg-light mb-3">
                <div class="card-header" v-if="ticketDetails.subject">{{ ticketDetails.subject }}</div>
                <div class="card-body">
                    <div v-if="ticketDetails.description">
                        <p class="formatted" v-html="ticketDetails.description"></p>
                    </div>
                </div>
            </div>

            <!--<div class="panel panel-primary">-->
                <!--<div class="panel-heading">-->
                    <!--<h4 class="panel-title" v-if="ticketDetails.subject">{{ ticketDetails.subject }}</h4>-->
                    <!--<div class="panel-control"><a href="javascript:void(0);"><i-->
                            <!--class="icon-cursor-move icon-arrow-up"></i></a><i class="icons icon-arrow-up"></i></div>-->
                <!--</div>-->

                <!--<div class="panel-body accordion2 collapse" style="display: block;">-->
                    <!--<div v-if="ticketDetails.description">-->
                        <!--<p class="formatted" v-html="ticketDetails.description"></p>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->


            <v-card class="mt-3" v-if="ticketDetails.conversation" v-for="conversation,index in ticketDetails.conversation" :key="index">
                <div class="row">
                    <v-card-title primary-title>
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-sm-12">
                                        <div class="pull-left profilePicture">
                                            <img class="" src="/public/assets/media/avatar1.png" width="42" height="42" alt="">
                                        </div>

                                        <div class="pull-left profileNameAndTime">
                                            <h5 class="m-0">
                                            <small title="group"
                                                   v-if="conversation.added_by_id == ticketDetails.user.id && conversation.added_type == 'Client'"
                                                   class="group-name">
                                                {{ ticketDetails.user.first_name }} {{ticketDetails.user.last_name}}
                                            </small>
                                            <small title="group"
                                                   v-else-if="conversation.added_by_id == ticketDetails.supporter.id && conversation.added_type == 'Supporter'"
                                                   class="group-name">
                                                {{ticketDetails.supporter.first_name}} {{ticketDetails.supporter.last_name}}
                                            </small>
                                            <small title="group" v-else>
                                                Admin
                                            </small>

                                            </h5>
                                            <small class="lastUpdatedTime"><span class="mr-15">{{ conversation.created_at }}</span></small>

                                        </div>

                                </div>
                            </div>

                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-12">
                                    <span class="mr-5" v-if="conversation.message">{{ conversation.message }}</span><br/>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <ul class="signers list-unstyled">
                                        <li v-for="file,index in conversation.attachment" :key="index">
                                            <a target="_blank" class="blue_font" :href="file.attachment">
                                                <i class="fa fa-file"></i>
                                                <span>{{file.attachment_title}}</span>
                                            </a>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </v-card-title>
                </div>
            </v-card>

            <v-card class="mt-5">

                <v-form ref="sendMessageForm" :model="sendMessageForm" class="register-container pa-3"
                        @keyup.native.enter="submit" @keydown="form.errors.clear($event.target.message);" enctype="multipart/form-data">
                    <v-card-text class="body-content">
                        <v-container grid-list-md>
                        <div class="row">
                            <div class="col-sm-12">
                                <v-textarea
                                        v-model="sendMessageForm.message"
                                        auto-grow
                                        label="Message"
                                        rows="2"
                                ></v-textarea>
                                <div class="form-error" v-if="form.errors.has('message')"
                                     v-text="form.errors.first('message')"></div>
                            </div>

                            <div class="col-sm-6">
                                <v-text-field v-model='attachName' label="Select Attachment"
                                              @click='pickFile'
                                              append-icon="attach_file"></v-text-field>
                                <input type="file"
                                       multiple
                                       style="display: none"
                                       ref="attachment"
                                       @change="onFilePicked">
                                <div class="form-error" v-if="form.errors.has('attachment')"
                                     v-text="form.errors.first('attachment')"></div>
                            </div>

                        </div>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="signfast-default-btn v-btn" @click="handleCancel">Cancel</v-btn>
                        <v-btn class="signfast-primary-btn v-btn" dark @click.native.prevent="sendMessage" :loading="isSubmitting">Send</v-btn>
                    </v-card-actions>
                </v-form>

            </v-card>

        </div>
    </div>
</template>


<script>
    import axios from 'axios';
    import Form from 'form-backend-validation';

    export default {
        data() {
            return {
                isSubmitting:false,
                loading:false,
                createTicketDialog: false,
                form: new Form(),
                ticketDetails:{},
                sendMessageForm: {
                    message:'',
                    attachment:'',
                    support_id:'',
                },
                attachName:'',
            }

        },
        mounted() {
            this.fetched();
//            this.fetchedCategory();
        },
        methods: {
            pickFile () {
                this.$refs.attachment.click ()
            },
            onFilePicked (e) {
                const files = e.target.files

                if(files[0] !== undefined) {

                    if(files.length == 1 ){
                        this.attachName = files[0].name;
                    }else if(files.length > 1){
                        this.attachName = files.length + " selected attachment.";
                    }

                    for( var i = 0; i < files.length; i++ ){
                        this.sendMessageForm['attachment['+i+']'] = files[i];
                    }

                    if(this.attachName.lastIndexOf('.') <= 0) {
                        return
                    }
                } else {
                    this.attachName = '';
                    this.sendMessageForm.attachment = '';
                }


            },
            fetched(page = 1) {
                this.loading = true;
                this.isSubmitting = true;
//                console.log(this.$route.params.ticketId);
                axios.get(route('api.support.tickets.details',[this.$route.params.ticketId])).then(response => {

                    this.ticketDetails = response.data.Data;

                    if (response.data.Success) {
                        this.sendMessageForm.support_id = this.ticketDetails.id;
                    } else {
                        toast.fire({
                            type: 'error',
                            title: response.data.Message,
                        });
                    }
                    this.isSubmitting = false;
                    this.loading = false;
                }).catch(e => {

                    toast.fire({
                        type: 'error',
                        title: e,
                    });
                    this.isSubmitting = false;
                    this.loading = false;
                });
            },
            sendMessage() {
                this.isSubmitting = true;
                if(this.sendMessageForm.message == '' && this.sendMessageForm.attachment == ''){
                    this.isSubmitting = false;
                    toast.fire({
                        type: 'error',
                        title: "Required message or attachment for sending message.",
                    });
                    return false;
                }
                this.form = new Form(this.sendMessageForm);
                this.form.post(route('api.support.message')).then((response) => {
                    if(response.Success){
                        toast.fire({
                            type: 'success',
                            title: response.Message,
                        });
                    this.fetched();
                    this.$refs.sendMessageForm.reset();
                    this.attachName = '';
                    this.sendMessageForm.attachment = '';

                }else{
                    toast.fire({
                        type: 'error',
                        title: response.Message,
                    });
                }
                this.isSubmitting = false;
                this.createTicketDialog = false;

            }).catch((error) => {
                console.log(error);
                this.isSubmitting = false;
            });
            },
            handleCancel(){
                this.$router.push({ name: 'frontend.support' });
            }
        },
        watch:{

        },
        components: {

        },
    }

</script>

<style>
    .support .support-header h3{
        margin: 8px 0px;
    }


    /* ul.signers li{
		float: left;
		padding: 0 8px;
		border: dashed 1px #0c59c7;
		margin: 5px 5px 5px 0;
		line-height: 1.8;
    } */

    .lastUpdatedTime{
        font-size: 12px;
    }
    .profileNameAndTime{
        padding-left: 5px;
    }
@media only screen and (max-width:767px){
	/* ul.signers li{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
    } */
}

</style>
