// import TagList from '../../components/Frontend/Tags/ListComponent';
// import OrganizationList from '../../components/Frontend/users/Organization/ListComponent';
// import OrganizationCreate from '../../components/Frontend/users/Organization/createComponent';
// import OrganizationEdit from '../../components/Frontend/users/Organization/editComponent';
// import Price from '../../components/Frontend/subscriptions/priceComponent';
// import Payment from '../../components/Frontend/subscriptions/PaymentComponent';
// import PlanUpdate from '../../components/Frontend/subscriptions/updatePlanComponent';
// import Invoice from '../../components/Frontend/subscriptions/invoiceComponent';
// import ForgotPassword from '../../components/Frontend/users/forgotPasswordComponent';
// import Profile from '../../components/Frontend/users/profileComponent';
// import ResetPassword from '../../components/Frontend/users/resetPasswordComponent';

// import UserDashboard from '../../components/Frontend/users/dashboardComponent';

// import UserList from '../../components/Frontend/users/listComponent';
// import UserCreate from '../../components/Frontend/users/createComponent';
// import UserEdit from '../../components/Frontend/users/editComponent';
// import UserApiLog from '../../components/Frontend/users/apiLogsComponent';
// import UserApiLogDetail from '../../components/Frontend/users/apiLogDetailsComponent';

// import RoleList from '../../components/Frontend/role/listComponent';
// import RoleGroupList from '../../components/Frontend/role/GroupListComponent';
// import RoleFieldsNames from '../../components/Frontend/role/FieldsNamesComponent';

// import EmailTemplatesList from '../../components/Frontend/EmailTemplates/listComponent';
// import EmailTemplatesEdit from '../../components/Frontend/EmailTemplates/editComponent';

// import UserDocumentList from '../../components/Frontend/users/DocumentsListComponent';
// import DocumentList from '../../components/Frontend/documents/listComponent';
// import DocumentTemplate from '../../components/Frontend/documents/templateComponent';
// import DocumentEditor from '../../components/Frontend/documents/EditorComponent';

// import EditorMaster from "../../components/Editor/editor-master";

// import FastFormList from '../../components/Frontend/FastForm/listComponent';
// import FastFormEditor from '../../components/Frontend/FastForm/EditComponent';
// import FastFormHtml from '../../components/Frontend/FastForm/FastFormHtmlComponent';
// import FastFormRecords from '../../components/Frontend/FastForm/FastFormRrecordsComponent';

// import TransferRequestList from '../../components/Frontend/TransferRequest/listComponent';

const { locales } = window.AsgardCMS;

export default [
    {
        path: '/organization/document/tag',
        name: 'frontend.document.tag',
        component: () => import(/* webpackChunkName:"tags" */'../../components/Frontend/Tags/listComponent'),
        // component: TagList,
        props: {
            pageTitle: 'Tags',
        },
    },
    // {
    //     path: '/organization/document/test',
    //     name: 'frontend.document.test',
    //     component: () => import(/* webpackChunkName:"tags" */'../../components/Frontend/documents/test'),
    //     // component: Tags,
    //     props: {
    //         pageTitle: 'Test',
    //     },
    // },
    {
        path: '/users/organizations',
        name: 'frontend.users.organization',
        component: () => import(/* webpackChunkName:"organization" */'../../components/Frontend/users/Organization/listComponent'),
        // component: OrganizationList,
        props: {
            pageTitle: 'Organization',
        },
    },
    {
        path: '/users/organization/registration',
        name: 'frontend.users.organization.registration',
        component: () => import(/* webpackChunkName:"organization" */'../../components/Frontend/users/Organization/createComponent'),
        // component: OrganizationCreate,
        props: {
            pageTitle: 'User Organization Create',
        },
    },
    {
        path: '/users/organization/:organizationId/edit',
        name: 'frontend.users.organization.edit',
        component: () => import(/* webpackChunkName:"organization" */'../../components/Frontend/users/Organization/editComponent'),
        // component: OrganizationEdit,
        props: {
            pageTitle: 'User Organization Edit',
        },
    },

    {
        path: '/organization/subscription/pricing',
        name: 'frontend.organization.subscription.pricing',
        component: () => import(/* webpackChunkName:"pricing" */'../../components/Frontend/subscriptions/priceComponent'),
        // component: Price,
        props: {
            pageTitle: 'Pricing',
        },
    },
    {
        path: '/organization/subscription/invoice/:invoiceId/pay',
        name: 'frontend.organization.subscription.invoice.pay',
        component: () => import(/* webpackChunkName:"payment" */'../../components/Frontend/subscriptions/paymentComponent'),
        // component: Payment,
        props: {
            pageTitle: 'Payment',
        },
    },
    {
        path: '/organization/subscription/:subscriptionId/:users/update',
        name: 'frontend.organization.subscription.update',
        component: () => import(/* webpackChunkName:"invoice" */'../../components/Frontend/subscriptions/updatePlanComponent'),
        // component: PlanUpdate,
        props: {
            pageTitle: 'Upgrade Plan',
        },
    },
    {
        path: '/organization/subscription/:subscriptionId/invoice',
        name: 'frontend.organization.subscription.invoice',
        component: () => import(/* webpackChunkName:"invoice" */'../../components/Frontend/subscriptions/invoiceComponent'),
        // component: Invoice,
        props: {
            pageTitle: 'Invoice',
        },
    },

    /* {
        path: '/organization/subscription',
        name: 'frontend.organization.subscription',
        component: UserSubscription,
        props: {
            pageTitle: 'Manage Subscription',
        },
    }, */

    {
        path: '/users/forgot',
        name: 'frontend.users.forgot',
        component: () => import(/* webpackChunkName:"forgot" */'../../components/Frontend/users/forgotPasswordComponent.vue'),
        // component: ForgotPassword,
        props: {
            pageTitle: 'Forgot Password',
        },
    },
    {
        path: '/users/reset/:id/:token',
        name: 'frontend.users.reset',
        component: () => import(/* webpackChunkName:"reset" */'../../components/Frontend/users/resetPasswordComponent.vue'),
        // component: ResetPassword,
        props: {
            pageTitle: 'Reset Password',
        },
    },
    {
        path: '/users/profile',
        name: 'frontend.users.profile',
        component: () => import(/* webpackChunkName:"profile" */'../../components/Frontend/users/profileComponent.vue'),
        // component: Profile,
        props: {
            pageTitle: 'Profile',
        },
    },

    // UserDashboard
    {
        path: '/users/dashboard',
        name: 'frontend.users.dashboard',
        component: () => import(/* webpackChunkName:"user-dashboard" */'../../components/Frontend/users/dashboardComponent.vue'),
        // component: UserDashboard,
        props: {
            pageTitle: 'Dashboard',
        },
    },

    // //user
    {
        path: '/organization/:organizationId/users/',
        name: 'admin.organization.users',
        component: () => import(/* webpackChunkName:"users" */'../../components/Frontend/users/listComponent.vue'),
        // component: UserList,
        props: {
            pageTitle: 'Users',
        },
    },
    {
        path: '/organization/users/',
        name: 'frontend.organization.users',
        component: () => import(/* webpackChunkName:"users" */'../../components/Frontend/users/listComponent.vue'),
        // component: UserList,
        props: {
            pageTitle: 'Users',
        },
    },
    {
        path: '/organization/users/create',
        name: 'frontend.organization.users.create',
        component: () => import(/* webpackChunkName:"create-users" */'../../components/Frontend/users/createComponent.vue'),
        // component: UserCreate,
        props: {
            pageTitle: 'Create user',
        },
    },
    {
        path: '/organization/users/:id/edit',
        name: 'frontend.organization.users.edit',
        component: () => import(/* webpackChunkName:"edit-users" */'../../components/Frontend/users/editComponent.vue'),
        // component: UserEdit,
        props: {
            pageTitle: 'Update user',
        },
    },
    {
        path: '/organization/users/:userId/logs',
        name: 'frontend.organization.users.logs',
        component: () => import(/* webpackChunkName:"users" */'../../components/Frontend/users/apiLogsComponent.vue'),
        // component: UserApiLog,
        props: {
            pageTitle: 'API Logs',
        },
    },
    {
        path: '/organization/users/logs/:logId',
        name: 'frontend.organization.users.logs.details',
        component: () => import(/* webpackChunkName:"users" */'../../components/Frontend/users/apiLogDetailsComponent.vue'),
        // component: UserApiLogDetail,
        props: {
            pageTitle: 'API Logs Details',
        },
    },

    // Role
    {
        path: '/organization/roles',
        name: 'frontend.organization.roles',
        component: () => import(/* webpackChunkName:"organization-role" */'../../components/Frontend/role/listComponent.vue'),
        // component: RoleList,
        props: {
            pageTitle: 'Role',
        },
    },
    {
        path: '/organization/roles/groups',
        name: 'frontend.organization.roles.groups',
        component: () => import(/* webpackChunkName:"organization-role-group" */'../../components/Frontend/role/GroupListComponent.vue'),
        // component: RoleGroupList,
        props: {
            pageTitle: 'Role Group',
        },
    },
    {
        path: '/organization/fieldnames',
        name: 'frontend.organization.fieldnames',
        component: () => import(/* webpackChunkName:"fields-names" */'../../components/Frontend/role/FieldsNamesComponent.vue'),
        // component: RoleFieldsNames,
        props: {
            pageTitle: 'Field Names',
        },
    },

    // Email Templates
    {
        path: '/organization/email/templates',
        name: 'frontend.organization.email.templates',
        component: () => import(/* webpackChunkName:"organization-role" */'../../components/Frontend/EmailTemplates/listComponent.vue'),
        // component: EmailTemplatesList,
        props: {
            pageTitle: 'Email Templates',
        },
    },
    {
        path: '/organization/email/:id/templates',
        name: 'frontend.organization.email.templates.edit',
        component: () => import(/* webpackChunkName:"organization-role" */'../../components/Frontend/EmailTemplates/editComponent.vue'),
        // component: EmailTemplatesEdit,
        props: {
            pageTitle: 'Edit Email Template',
        },
    },

    // Document
    {
        path: '/users/documents:category_id?',
        name: 'frontend.users.documents',
        component: () => import(/* webpackChunkName:"documents" */'../../components/Frontend/documents/listComponent.vue'),
        // component: DocumentList,
        props: {
            pageTitle: 'Documents',
        },
    },
    {
        path: '/organization/users/:id/documents',
        name: 'frontend.organization.users.documents',
        component: () => import(/* webpackChunkName:"users-documents" */'../../components/Frontend/users/DocumentsListComponent.vue'),
        // component: UserDocumentList,
        props: {
            pageTitle: 'Users Documents',
        },
    },
    {
        path: '/users/documents/archive',
        name: 'frontend.users.archive',
        component: () => import(/* webpackChunkName:"documents" */'../../components/Frontend/documents/listComponent.vue'),
        // component: DocumentList,
        props: {
            pageTitle: 'Archive',
        },
    },
    {
        path: '/users/documents/template:category_id?',
        name: 'frontend.users.template',
        component: () => import(/* webpackChunkName:"templates" */'../../components/Frontend/documents/templateComponent.vue'),
        // component: DocumentTemplate,
        props: {
            pageTitle: 'Template',
        },
    },
    {
        path: '/users/documents/:id',
        name: 'frontend.users.document.edit',
        component: () => import(/* webpackChunkName:"editor" */'../../components/Frontend/documents/EditorComponent.vue'),
        // component: DocumentEditor,
        props: {
            pageTitle: 'document detail',
        },
    },
    {
        path: '/users/documents/template/:id',
        name: 'frontend.users.document.template.edit',
        component: () => import(/* webpackChunkName:"editor" */'../../components/Frontend/documents/EditorComponent.vue'),
        // component: DocumentEditor,
        props: {
            pageTitle: 'document template detail',
        },
    },
    /* {
        path: '/users/documents/group',
        name: 'frontend.users.documents.group',
        component: DocumentsGroup,
        props: {
            pageTitle: 'Documents Group',
        },
    },
    {
        path: '/users/documents/group/list/:groupId',
        name: 'frontend.users.documents.group.list',
        component: GroupDocList,
        props: {
            pageTitle: 'Group documents',
        },
    }, */

    // Editor
    {
        path: '/document/invite/:inviteId/:accessToken',
        name: 'frontend.document.invite.sign',
        component: () => import(/* webpackChunkName:"editor-master" */'../../components/Editor/editor-master.vue'),
        // component: EditorMaster,
    },
    {
        path: '/document/:id/webapp/editor/:accessToken',
        name: 'frontend.document.edit',
        component: () => import(/* webpackChunkName:"editor" */'../../components/Frontend/documents/EditorComponent.vue'),
        // component: DocumentEditor,
        // component: Editor,
        props: {
            pageTitle: 'Document Detail',
        },
    },

    // FastForm
    {
        path: '/users/fastform',
        name: 'frontend.users.fastform',
        component: () => import(/* webpackChunkName:"fastform" */'../../components/Frontend/FastForm/ListComponent.vue'),
        // component: FastFormList,
        props: {
            pageTitle: 'Fast Forms',
        },
    },
    {
        path: '/users/fastform/:id/edit',
        name: 'frontend.users.fastform.edit',
        component: () => import(/* webpackChunkName:"fastform-edit" */'../../components/Frontend/FastForm/EditComponent.vue'),
        // component: FastFormEditor,
        props: {
            pageTitle: 'Fast Forms Edit',
        },
    },
    {
        path: '/users/fastform/:id/group',
        name: 'frontend.users.fastform.group',
        component: () => import(/* webpackChunkName:"group" */'../../components/Frontend/FastForm/EditComponent.vue'),
        // component: FastFormEditor,
        props: {
            pageTitle: 'Fast Forms Edit',
        },
    },
    {
        path: '/users/fastform/:id/fillform',
        name: 'frontend.users.fastform.fillform',
        component: () => import(/* webpackChunkName:"fastform-fill-form" */'../../components/Frontend/FastForm/FastFormHtmlComponent.vue'),
        // component: FastFormHtml,
        props: {
            pageTitle: 'FastForm fillform',
        },
    },
    {
        path: '/users/fastform/:id/editform/:dataId',
        name: 'frontend.users.fastform.editform',
        component: () => import(/* webpackChunkName:"fastform-edit-form" */'../../components/Frontend/FastForm/FastFormHtmlComponent.vue'),
        // component: FastFormHtml,
        props: {
            pageTitle: 'FastForm editform',
        },
    },
    {
        path: '/users/fastform/:id/records',
        name: 'frontend.users.fastform.records',
        component: () => import(/* webpackChunkName:"fastform-records" */'../../components/Frontend/FastForm/FastFormRrecordsComponent.vue'),
        // component: FastFormRecords,
        props: {
            pageTitle: 'FastForm Records',
        },
    },

    // TransferRequest
    {
        path: '/users/transferrequests',
        name: 'frontend.users.transferrequests.index',
        component: () => import(/* webpackChunkName:"transferrequests" */'../../components/Frontend/TransferRequest/listComponent.vue'),
        // component: TransferRequestList,
        props: {
            pageTitle: 'Transfer Request Records',
        },
    },
];
