
// import Support from '../../components/Frontend/listComponent.vue';
// import Details from '../../components/Frontend/detailsComponent.vue';

// const locales = window.AsgardCMS.locales;

import ListComponent from '../../components/Frontend/listComponent';
import DetailsComponent from '../../components/Frontend/detailsComponent';

export default [
    {
        path: '/support',
        name: 'frontend.support',
        // component: () => import(/* webpackChunkName:"Support" */'../../components/Frontend/listComponent.vue'),
        component: ListComponent,
        props: {
            pageTitle: 'Support',
        },
    },
    {
        path: '/support/ticket/details/:ticketId',
        name: 'frontend.support.ticket.details',
        // component: () => import(/* webpackChunkName:"Details" */'../../components/Frontend/detailsComponent.vue'),
        component: DetailsComponent,
        props: {
            pageTitle: 'Details',
        },
    },
];
