<template>
    <div>
        <div class="header navigation">
            <div class="container">
                <div class="form-row align-items-center">
                    <div class="col-8 col-xl-2 col-lg-2 col-sm-9 col-md-9 logo">
                        <a :href="activeUser.id != 1 ? '/users/dashboard' : ''">
                            <img v-if="custom_logo !== undefined && custom_logo !=='' && custom_logo != null"
                                 :src="'/users/profile-image/'+new Date().getTime()+'.png'" alt="" style="height: 43px;width: 92px;">
                            <img v-else src="/assets/media/logo/logo.svg" alt="SignFast" style="height: 43px">
                        </a>
                    </div>
                    <div class="col-2 MenuIcon d-lg-none text-right"><i @click="toggleMenu()" class="fa fa-bars"></i></div>

                    <div v-if="activeUser.id != 1" class="col-12 col-xl-9 col-lg-9 col-sm-12 col-md-9 top-nav-menu">
                        <div class="navbar">
                            <ul>
                                <li v-if="activeUser.organization.type !== 'api'">
                                    <input type="checkbox" id="menu-get-signature">
                                    <label for="menu-get-signature">
                                        <a class="title more get-signature-sub">
                                            Get Signatures
                                            <span class="icon icon-down-arrow-line"></span>
                                        </a>
                                    </label>
                                    <ul>
                                        <li><a class="title" v-on:click="handleOpen('templates')"><span>Use Template </span></a></li>
                                        <li>
                                            <input type="checkbox" id="menu-upload-signers">
                                            <label for="menu-upload-signers">
                                                <a class="title more get-signature-sub">
                                                    Upload & Add Signers
                                                    <span class="icon icon-down-arrow-line"></span>
                                                </a>
                                            </label>
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0)" class="title documentUploadBtn">
                                                        <span>Browse Computer</span>
                                                        <input type="file"
                                                               class="btn btn-primary upload-input"
                                                               accept="application/pdf,application/doc,text/plain,image/jpeg,image/jpg,image/png"
                                                               v-on:change="upload('uploadAndAddSigners')"
                                                               v-on:click="uploadClickEvent('uploadAndAddSigners')"
                                                               ref="documentfile1"
                                                               multiple
                                                               v-loading="loading">
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"  v-on:click="driveIconClicked('uploadAndAddSigners')" class="title documentUploadBtn">
                                                        <span> Google Drive </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"  v-on:click="dropboxIconClicked('uploadAndAddSigners')" class="title documentUploadBtn">
                                                        <span> Dropbox </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"  v-on:click="launchOneDrivePicker('uploadAndAddSigners')" class="title documentUploadBtn">
                                                        <span> One Drive </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <input type="checkbox" id="menu-upload-apply">
                                            <label for="menu-upload-apply">
                                                <a class="title more get-signature-sub">
                                                    Upload & Apply Overlay
                                                    <span class="icon icon-down-arrow-line"></span>
                                                </a>
                                            </label>
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0)" class="title documentUploadBtn">
                                                        <span> Browse Computer</span>
                                                        <input type="file"
                                                               class="btn btn-primary upload-input"
                                                               accept="application/pdf,application/doc,text/plain,image/jpeg,image/jpg,image/png"
                                                               v-on:change="upload('uploadAndApplyOverlay')"
                                                               v-on:click="uploadClickEvent('uploadAndApplyOverlay')"
                                                               ref="documentfile2"
                                                               multiple
                                                               v-loading="loading">
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"  v-on:click="driveIconClicked('uploadAndApplyOverlay')" class="title documentUploadBtn">
                                                        <span> Google Drive </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"  v-on:click="dropboxIconClicked('uploadAndApplyOverlay')" class="title documentUploadBtn">
                                                        <span> Dropbox </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"  v-on:click="launchOneDrivePicker('uploadAndApplyOverlay')" class="title documentUploadBtn">
                                                        <span> One Drive </span>
                                                    </a>
                                                </li>
                                            </ul>

                                        </li>
                                    </ul>
                                </li>

                                <li v-if="activeUser.organization.type !== 'api'"><a class="title" v-on:click="handleOpen('documents')"><span>Documents </span></a></li>

                                <li v-if="activeUser.organization.type !== 'api'"><a class="title" @click="handleOpen('templates')"><span>Templates </span></a></li>
                                <li v-if="activeUser.organization.type !== 'api'"><a class="title" v-on:click="handleOpen('FastForm')">FastForms<sup>TM</sup></a></li>
                                <li>
                                    <input type="checkbox" id="menu-browsebylakerivername3">
                                    <label for="menu-browsebylakerivername3">
                                        <a class="title more get-signature-sub">
                                            Manage
                                            <span class="icon icon-down-arrow-line"></span>
                                        </a>
                                    </label>
                                    <ul>
                                        <!--<li><a class="title" v-on:click="handleOpen('documents')" >Documents </a></li>-->
                                        <!--<li><a class="title" v-on:click="handleOpen('templates')" >Templates </a></li>-->
                                        <!-- <li><a v-on:click="handleOpen('documentGroup')" class="title">Document Group</a></li> -->
                                        <li v-if="activeUser.organization.type !== 'api'"><a v-on:click="handleOpen('archive')" class="title">Archive</a></li>
                                        <li v-if="activeUser.is_default == 'yes'"><a href="/organization/users" class="title">Users</a></li>
                                        <li v-if="activeUser.organization.type !== 'api'"><a href="/organization/roles/groups" class="title">Role Groups</a></li>
                                        <li v-if="activeUser.organization.type !== 'api'"><a href="/organization/roles" class="title">Roles</a></li>
                                        <li v-if="activeUser.organization.type !== 'api'"><a href="/organization/fieldnames" class="title">Pdf Field Naming</a></li>
                                        <li v-if="activeUser.organization.type !== 'api'"><a href="/organization/document/tag" class="title">Tags</a></li>
                                        <li v-if="activeUser.is_default == 'yes'"><a href="/users/transferrequests" class="title">Transfer Requests</a></li>
                                        <li v-if="activeUser.is_default == 'yes'"><a href="/organization/email/templates" class="title">Email Templates</a></li>
                                        <li v-if="purchaseInfo != undefined && purchaseInfo.subscription_type == 'api'"><a href="/users/organizations" class="title">Organizations</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div v-else class="col-12 col-xl-9 col-lg-9 col-sm-12 col-md-9 top-nav-menu">
                        <div class="navbar">
                            <ul>
                                <li><a class="title" @click="handleOpen('templates')"><span>Templates </span></a></li>
                                <li><a class="title" v-on:click="handleOpen('FastForm')">FastForms<sup>TM</sup></a></li>
                                <li><a href="/organization/roles/groups" class="title">Role Groups</a></li>
                                <li><a href="/organization/roles" class="title">Roles</a></li>
                                <li><a href="/organization/document/tag" class="title">Tags</a></li>
                            </ul>
                        </div>
                    </div>
                    <!--                fd2563efa313 -->
                    <div class="col-12 col-xl-1 col-lg-1 col-sm-12 col-md-1">
                        <div class="row align-items-center">
                            <div class="col-xl-10 col-lg-10"></div>

                            <div class="col-xl-12 col-lg-12 text-right" for="btnGroupDrop1">
                                <!--<span class="pro-name">{{ activeUser.first_name }} {{ activeUser.last_name }}</span>-->
                                <div class="btn-group user-name" role="group">
                                    <button id="btnGroupDrop1" type="button" class="dropdown-toggle user-profile-icon" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false" data-placement="top" title="More">
                                        <i class="icon-user" aria-hidden="true" style="font-size: 20px;"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right user-profile-menu" aria-labelledby="btnGroupDrop1">
                                        <div class="h6 text-center text-primary pt-3 pb-3 border-bottom">
                                            <small class="text-info">Organization:</small><br/>
                                            <i class="fa fa-check-square-o profile-menu-icon" aria-hidden="true"></i>{{activeUser.organization.company}}
                                        </div>
                                        <ul>
                                            <li v-if="activeUser.organizations!=undefined && activeUser.organizations.length > 1" class="text-primary pl-3"><small>Switch Organization To:</small></li>
                                            <li v-if="activeUser.organizations!=undefined && activeUser.organizations.length > 0 && organizationUser.organization.id!=activeUser.organization.id" @click="switchOrganizationAccount(organizationUser.display_id)" v-for="(organizationUser,index) in activeUser.organizations" class="dropdown-item alert alert-box border-0 remove-border-radious m-0" :class="organizationUser.organization.id==activeUser.organization_id ? 'text-primary':''">
                                                <i class="fa fa-bank profile-menu-icon" aria-hidden="true"></i>{{ organizationUser.organization.company }}
                                            </li>
                                            <li v-if="activeUser.organizations!=undefined && activeUser.organizations.length > 0 && activeUser.organizations[0].organization.id!=activeUser.organization.id"><div class="dropdown-divider"></div></li>
                                            <li v-if="activeUser.id != 1">
                                                <router-link class="dropdown-item" :to="{name: 'frontend.users.profile'}"><i class="fa fa-user profile-menu-icon" aria-hidden="true"></i>Profile</router-link>
                                            </li>
                                            <li v-if="activeUser.is_default == 'yes' && activeUser.id != 1">
                                                <router-link class="dropdown-item" :to="{name: 'frontend.support'}"><i class="fa fa-support profile-menu-icon" aria-hidden="true"></i>Support</router-link>
                                            </li>
<!--                                            <li><div class="dropdown-divider"></div></li>-->
                                            <li><a class="dropdown-item" href="#" @click="handleOpen('Logout')"><i class="fa fa-power-off profile-menu-icon" aria-hidden="true"></i>Logout</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Field Import Dialog-->
            <v-dialog v-model="filedImportDialog" width="500" persistent class="mydialog">
                <v-card class="dialog">
                    <v-card-title class="headline grey lighten-2 header-title" primary-title>Import Document Form Fields</v-card-title>
                    <v-card-text class="body-content pl-3">
                        <p>
                            It appears that form fields currently exist on this document. SignFast makes it easy to create templates based on preexisting roles that
                            you create. SignFast has a custom naming convention for your form fields so that document fields can easily be assigned to roles. For
                            more information, <a target="_blank" href="/organization/fieldnames">click here</a>. If you have no intention of using the fields on this
                            document to create a template, then select option 4 or 5.
                        </p>
                        <v-radio-group v-model="uploadForm.option">
                            <v-radio color="blue" label="1. Assign all fields to single role" value="assign_to_role"></v-radio>
                            <div class="offset-2" v-if="uploadForm.option == 'assign_to_role'">
                                <div class="recipients-wrap">
                                    <div class="form-group row m-0 mb-1" v-loading="organizationRoleLoading" :loading="organizationRoleLoading">
                                        <select id="signerRole" class="form-control shadow-xs" ref="signerRole" title="Role"
                                                v-model="uploadForm.role">
                                            <option disabled value="">Role</option>
                                            <option v-for="(role,roleIndex) in organizationRole" :key="roleIndex">{{ role }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <v-radio color="blue" label="2. Assign fields to multiple roles" value="assign_to_master"
                                     v-if="purchaseInfo != null && purchaseInfo.multiple_signers == 'no'" :disabled="true"
                                     title="multiple signer not allowd."></v-radio>
                            <v-radio color="blue" label="2. Assign fields to multiple roles" value="assign_to_master" v-else></v-radio>
                            <v-radio color="blue" label="3. Import fields but don't assign to any role" value="import_as_document_fields"></v-radio>
                            <v-radio color="blue" label="4. Keep form data only" value="keep_form_data"></v-radio>
                            <v-radio color="blue" label="5. Delete all form fields and data" value="delete_form_data"></v-radio>

                        </v-radio-group>


                        <div class="mt-3">
                            <p v-if="uploadForm.option == 'assign_to_role' ">Select role from master role list to assign all fields to. Assigned signer will be able
                                to edit and/or sign fields. You can also manually reassign fields if you add signers in the next step.</p>
                            <p v-if="uploadForm.option == 'assign_to_master' ">If this document contains form fields that have followed SignFast's custom naming
                                convention found HERE, and you have setup master roles, then use this option.</p>
                            <p v-if="uploadForm.option == 'keep_form_data' ">Existing form data only is imported and placed on document.</p>
                            <p v-if="uploadForm.option == 'delete_form_data' ">Delete all PDF form fields and their data. Document will be flattened with no fields
                                or pre-existing roles.</p>
                            <p v-if="uploadForm.option == 'import_as_document_fields' "> Select this option if you wish to keep all of the fields and assign those
                                fields to roles you create later.</p>
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer class="mergeAlertMessage"></v-spacer>
                        <v-btn color="primary" class="btn signfast-default-btn" flat @click.native="filedImportDialog = false">Close</v-btn>
                        <v-btn slot="activator" class="btn signfast-primary-btn" color="primary" flat @click.native="upload">Continue</v-btn>
                        <!--<v-btn slot="activator" color="black lighten-2" dark>Create Document Group</v-btn>-->
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </div>
        <div class="container" v-if="purchaseInfo == null && free_trial_days > 0 && activeUser.id != 1 && activeUser.is_default==='yes' && this.$route.params.subscriptionId == null">
            <div class="form-group alert trial-warning-message mt-2"
                 :class="activeUser.remaining_free_trial_days > 0 && activeUser.expired_trial==false ? 'alert-warning': 'alert-danger'">
                <span>!</span>
                <h5 class="m-0">
                    <template v-if="activeUser.remaining_free_trial_days > 0 && activeUser.expired_trial==false">
                        Your free trial will expire in <strong>{{ activeUser.remaining_free_trial_days }}</strong> days;
                    </template>
                    <template v-else-if="activeUser.remaining_free_trial_days == 0 && activeUser.expired_trial==false">
                        Your free trial will expire <strong>Today</strong>;
                    </template>
                    <template v-else>

                        Your free trial <strong>expired;</strong>
                    </template>
                    <template v-if="$route.name !== 'frontend.organization.subscription.pricing'">
                        Please purchase a subscription <a href="javascript:void(0)" class="text-primary on-hover-underline" @click="displaySubscription" style="font-size:inherit;">by clicking here.</a>
                    </template>
                    <template v-else>Please purchase a subscription.</template>
                </h5>
                <!--<h5 class="m-0" v-if="activeUser.remaining_free_trial_days > 0 && activeUser.expired_trial==false">
                    Your free trial will expire in <strong>{{ activeUser.remaining_free_trial_days }}</strong> days, please <a href="javascript:void(0)" @click="displaySubscription" style="font-size:inherit;">upgrade</a> before your trial ends.
                </h5>
                <h5 v-else>
                    Your free trial expired, please <a href="javascript:void(0)" @click="displaySubscription" style="font-size:inherit;">upgrade</a> before your trial ends.
                </h5>-->
            </div>
        </div>
    </div>
</template>

<script>

    import DocumentHelper from '../../../mixins/DocumentHelper';

    export default {
        mixins: [DocumentHelper],
        props: ['activeUser'],
        data() {
            return {
                show: false,
                isSubmitting: false,
                loading: false,
                custom_logo: '',
                filedImportDialog: false,
                organizationRole: [],
                organizationRoleLoading: false,
                documentCategory: {},
                type: 'document',
                free_trial_days: 0,
                purchaseInfo: {},
            };
        },
        mounted() {
            if (this.activeUser != '') {
                this.fetchActiveUser();
            }

            // console.log("activeUser",this.activeUser);
        },
        methods: {
            switchOrganizationAccount(userId) {
                localStorage.setItem('userLodgedIn', 'true');
                window.location.href = route('frontend.organization.users.login', userId);
            },
            toggleMenu() {
                $('.top-nav-menu').toggle();
            },
            displaySubscription() {
                this.$router.push({ name: 'frontend.organization.subscription.pricing' });
                // this.fetchedSubscription();
            },

            fetchActiveUser() {
                // console.log('this.activeUser ~> ', this.activeUser);
                // console.log('this.activeUser.organization.free_trial_days ~> ', this.activeUser.organization.free_trial_days);
                // console.log('this.activeUser.organization.purchase ~> ', this.activeUser.organization.purchase);
                this.free_trial_days = this.activeUser.organization.free_trial_days;
                this.purchaseInfo = this.activeUser.organization.purchase;
                this.custom_logo = this.activeUser.setting.custom_logo;
            },

            /* fetch Organization Roles */
            fetchedOrganizationRole() {
                this.organizationRoleLoading = true;
                axios.get(route('api.organization.roles', { page: 'all', fields: 'role_name' })).then((response) => {
                    if (response.data.Success) {
                        var response = response.data.Data;
                        response.forEach((role) => {
                            this.organizationRole.push(role.role_name);
                        });
                        this.organizationRoleLoading = false;
                    } else {
                        toast.fire({
                            type: 'error',
                            title: response.Message,
                        });
                        this.organizationRoleLoading = false;
                    }
                }).catch((e) => {
                    toast.fire({
                        type: 'error',
                        title: e,
                    });
                    this.organizationRoleLoading = false;
                });
            },
            handleOpen(key) {
                if (key == 'dashboard') {
                    this.$router.push({ name: 'frontend.users.dashboard' });
                } else if (key == 'documents') {
                    window.location.href = route('frontend.document.index');
                    // this.$router.push({ name: 'frontend.users.documents'});
                } else if (key == 'archive') {
                    window.location.href = route('frontend.organization.document.archive');
                    // this.$router.push({ name: 'frontend.users.archive'});
                } else if (key == 'templates') {
                    window.location.href = '/users/documents/template';
                    // this.$router.push({ name: 'frontend.users.template'});
                } else if (key == 'documentGroup') {
                    this.$router.push({ name: 'frontend.users.documents.group' });
                } else if (key == 'Logout') {
                    localStorage.removeItem('activeUser');
                    localStorage.removeItem('profile-action');
                    localStorage.clear();
                    location.href = route('frontend.users.logout');
                } else if (key == 'Profile') {
                    localStorage.removeItem('profile-action');
                    location.href = route('frontend.users.profile');
                } else if (key == 'Support') {
                    location.href = route('frontend.support');
                } else if (key == 'FastForm') {
                    location.href = route('frontend.users.fastform');
                } else if (key == 'Organization') {
                    location.href = route('frontend.users.organization');
                }
            },
        },
        watch: {
            // "searchForm.search_field":function () {
            //     console.log("search filed click");
            //     var temp = this.searchForm.search;
            //     console.log("First :" +temp);
            //     this.clearSearch();
            //     console.log("Second :" + temp);
            //     this.searchDocument();
            // }
        },
    };
</script>

<style>
    @import '/themes/flatly/css/navbar.css';

    /*.mydialog{*/
    /*    z-index: 200 !important;*/

    /*}*/
    .uploadBoxDivider {
        /*padding-top: 5px;*/
    }

    .searchBoxDivider {
        padding-top: 5px;
    }

    .uploadBoxDivider button.file.btn.btn-primary.documentUploadBtn.v-btn {
        background-color: white !important;
        box-shadow: none;
        border: none;
        color: rgba(0, 0, 0, .54) !important;
        border-radius: 30px;
        cursor: pointer !important;
        text-transform: capitalize !important;
    }

    .documentUploadBtn {
        position: relative;
        overflow: hidden;
    }

    .documentUploadBtn input {
        position: absolute;
        font-size: 50px;
        opacity: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer !important;
    }

    .user-name {
        text-transform: capitalize;
    }

    .search-field {
        background-color: white;
        position: absolute;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
        width: 200px;
    }

    .search-field label {
        color: #202020;
    }

    .searchBox .v-input__slot {
        box-shadow: none !important;
        /*border-radius: 30px !important;*/
    }

    .searchBox .v-text-field__details {
        margin-bottom: 0 !important;
    }

    .user-profile-menu li{
        cursor: pointer;
    }

    .profile-menu-icon{
        width: 20px;
        display: inline-block;
        text-align: center;
    }
</style>
