<template>
    <div class="master-layout" v-if="activeUser !='' && activeUser != null && activeUser != undefined">
        <navigation :activeUser="activeUser"></navigation>
        <div class="container route-content">
            <div class="row">
                <div class="col-sm-12 main-container">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <custom-footer></custom-footer>
    </div>
</template>
<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                activeUser:'',
            };
        },
        mounted() {
            // console.log("window.actiiveUser: ",window.actiiveUser);
            /* Need to confirm this below line as did comment for now. */
            // localStorage.clear();
            if (window.actiiveUser != '' && window.actiiveUser != undefined) {
                // console.log('masterLayout > mounted > if..');
                localStorage.setItem('activeUser', window.actiiveUser);
                this.activeUser = JSON.parse(window.actiiveUser);
                this.settings = this.activeUser.setting;
            }else {
                // console.log('masterLayout > mounted > else..');
                this.fetchActiveUser();
            }
        },
        methods: {
            fetchActiveUser() {
                // console.log('masterLayout > fetchActiveUser is called..');
                localStorage.removeItem('activeUser');
                axios.get(route('api.organization.user.active')).then((response) => {
                    this.activeUser = response.data.Data;
                    console.log("masterLayout > fetchActiveUser ~> ",this.activeUser);
                    this.activeUser.documentUrl = "/public/assets/organization/" + this.activeUser.organization_id + "/" + this.activeUser.id + "/documents/";
                    localStorage.setItem('activeUser', JSON.stringify(this.activeUser));
                    localStorage.removeItem('userLodgedIn');
                    // console.log(this.activeUser);
                });
            }
        },
        watch:{

        }
    }
</script>

<style>
    .route-content{
        margin-bottom: 50px !important;
    }
    /* body.swal2-toast-shown .swal2-container.swal2-top {
        width: auto;
        !*display: table;*!
    }
    .swal2-popup.swal2-toast.swal-signfast {
        box-shadow: 0 0 0.625em #0c59c766;
    }
    .swal2-popup.swal2-toast.swal-signfast .swal2-title {
        margin: 0;
    }
    .swal2-popup.swal2-toast {
        padding: 0.5em 1em;
    }
    .swal2-popup.swal2-toast .swal2-title {
        margin: 0.75em;
    } */
</style>
