<template>

    <v-footer dark height="auto" class="justify-center">
        <!--<v-card class="flex" flat tile>-->
            <!--<v-card-actions class="footer-color justify-center">-->
                Copyright &copy; 2020 — <strong>SignFast.com, All Rights Reserved.</strong>
            <!--</v-card-actions>-->
        <!--</v-card>-->
    </v-footer>

</template>

<script>
    export default {
        data() {
            return {

            };
        },
        mounted() {

        },
        methods: {

        },
        watch:{

        }
    }
</script>
<style>
    .v-footer{
        height: auto;
        bottom: 0;
        position: fixed;
        width: 100%;
        z-index: 3;
    }

</style>
