<template>
  <div class="support">
    <div class="support-header">
      <div class="row">
        <div class="col-6 col-sm-4">
          <h3>Manage your tickets</h3>
        </div>
        <div class="col-6 col-sm-8 text-right">
          <v-btn class="signfast-default-btn v-btn btn" @click="createTicketDialog=true">Generate Ticket</v-btn>
        </div>
      </div>
    </div>

    <div class="support-list">
      <div class="row">
        <div class="col-sm-12">
          <v-data-table
              :headers="headers"
              :items="tickets.data"
              class="elevation-1 responsive"
              hide-actions
              v-loading="loading">
            <template slot="items" slot-scope="props">
              <td :data-label="headers[0].text">{{ props.item.ticket_id }}</td>
              <td :data-label="headers[1].text">{{ props.item.subject }}</td>
              <td :data-label="headers[2].text">{{ props.item.priority }}</td>
              <td :data-label="headers[3].text">{{ props.item.status }}</td>
              <td :data-label="headers[4].text">{{ props.item.created_at | formatDate }}</td>
              <td :data-label="headers[5].text" class="">
                <v-icon small class="mr-2" @click="handleDetails(props.item.ticket_id)">
                  fa fa-eye
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </div>
        <div v-if="tickets.total > 0" class="col-sm-12 pagination-wrap">
          <div class="row align-items-center pt-3">
            <div class="col-sm-6">
              <span>Showing {{ tickets.from }} to {{ tickets.to }} of {{ tickets.total }} results</span>
            </div>
            <div class="col-sm-6">
              <!--<pagination class="pull-right" :data="tags" @pagination-change-page="fetched"></pagination>-->
              <v-pagination v-model="pagination.currentPage" class="pull-right" :length="pages" @input="onPageChange"></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create Ticket Dialog-->
    <v-dialog v-model="createTicketDialog" width="750">
      <v-card class="dialog">
        <v-card-title class="headline grey lighten-2 header-title" primary-title>Generate new ticket</v-card-title>
        <v-card-text class="body-content">
          <v-container grid-list-md>
            <v-form ref="createTicketForm" :model="createTicketForm"
                    class="register-container pa-3"
                    @keyup.native.enter="submit"
                    @keydown="form.errors.clear($event.target.subject);">
              <v-card-text>
                <div class="row">
                  <div class="col-sm-6">
                    <v-text-field
                        v-model="createTicketForm.subject"
                        label="Subject"
                        required
                    ></v-text-field>
                    <div class="form-error" v-if="form.errors.has('subject')"
                         v-text="form.errors.first('subject')"></div>
                  </div>

                  <div class="col-sm-6">
                    <v-select
                        :items="category.Data"
                        label="Category"
                        v-model="createTicketForm.category_id"
                        item-text="value"
                        item-value="key"
                    ></v-select>

                    <div class="form-error" v-if="form.errors.has('category_id')"
                         v-text="form.errors.first('category_id')"></div>
                  </div>

                  <div class="col-sm-12">
                    <v-textarea
                        v-model="createTicketForm.description"
                        auto-grow
                        label="Description"
                        rows="2"
                    ></v-textarea>
                    <div class="form-error" v-if="form.errors.has('description')"
                         v-text="form.errors.first('description')"></div>
                  </div>

                  <div class="col-sm-6">
                    <v-text-field v-model='attachName' label="Select Attachment"
                                  @click='pickFile'
                                  append-icon="attach_file"></v-text-field>
                    <input type="file"
                           style="display: none"
                           ref="attachment"
                           @change="onFilePicked"
                           multiple>
                    <div class="form-error" v-if="form.errors.has('attachment')"
                         v-text="form.errors.first('attachment')"></div>
                  </div>

                </div>
              </v-card-text>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="signfast-default-btn v-btn" @click="createTicketDialog = false">Cancel</v-btn>
          <v-btn class="signfast-primary-btn v-btn" :loading="isSubmitting" @click="CreateTicket">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import axios from 'axios';
import Form from 'form-backend-validation';

export default {
  data() {
    return {
      isSubmitting: false,
      loading: false,
      createTicketDialog: false,
      form: new Form(),
      category: {},
      tickets: {},
      headers: [
        {text: 'Ticket #', value: 'ticket_id'},
        {text: 'Subject', value: 'subject'},
        {text: 'Priority', value: 'priority'},
        {text: 'Status', value: 'status'},
        {text: 'Created At', value: 'created_at'},
        {text: 'Operation', value: 'operation'},
      ],
      createTicketForm: {
        category_id: '',
        subject: '',
        description: '',
        attachment: '',
        added_type: 'Client'
      },
      attachName: '',
      pagination: {
        currentPage: 1,
        totalItems: 0,
        rowsPerPage: 10,
      }
    }
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
        return 0;
      }
      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    }
  },
  mounted() {
    this.fetched();
    this.fetchedCategory();
  },
  methods: {
    onPageChange() {
      this.fetched();
    },
    pickFile() {
      this.$refs.attachment.click()
    },
    onFilePicked(e) {
      const files = e.target.files

      if (files[0] !== undefined) {

        if (files.length == 1) {
          this.attachName = files[0].name;
        } else if (files.length > 1) {
          this.attachName = files.length + " selected attachment.";
        }

        for (var i = 0; i < files.length; i++) {
          this.createTicketForm['attachment[' + i + ']'] = files[i];
        }

        if (this.attachName.lastIndexOf('.') <= 0) {
          return
        }
      } else {
        this.attachName = '';
        this.createTicketForm.attachment = '';
      }
    },
    fetched() {
      this.loading = true;
      this.isSubmitting = true;
      axios.get(route('api.support.user.tickets', {page: this.pagination.currentPage})).then(response => {
        if (response.data.Success) {
          this.tickets = response.data.Data.ticket;
          this.pagination.totalItems = this.tickets.total;
        } else {
          this.$notify.error({
            title: 'Error',
            message: response.data.Message,
          });
        }
        this.isSubmitting = false;
        this.loading = false;
      }).catch(e => {
        this.$notify.error({
          title: 'Error',
          message: e,
        });
        this.isSubmitting = false;
        this.loading = false;
      })
    },
    fetchedCategory(page = 1) {
      this.loading = true;
      this.isSubmitting = true;
      axios.get(route('api.support.category')).then(response => {
        if (response.data.Success) {
          this.category = response.data;
        } else {
          toast.fire({
            type: 'error',
            title: response.data.Message,
          });
        }
        this.isSubmitting = false;
        this.loading = false;
      }).catch(e => {
        toast.fire({
          type: 'error',
          title: e,
        });

        this.isSubmitting = false;
        this.loading = false;
      });
    },
    handleDetails(ticketId) {
      location.href = route('frontend.support.ticket.details', {ticketId});
    },
    CreateTicket() {
      this.isSubmitting = true;
      this.form = new Form(this.createTicketForm);
      this.form.post(route('api.support.user.tickets')).then((response) => {
        if (response.Success) {
          toast.fire({
            type: 'success',
            title: response.Message,
          });

          this.fetched();
          this.$refs.createTicketForm.reset();
          this.attachName = '';
          this.createTicketForm.attachment = '';

        } else {

          toast.fire({
            type: 'error',
            title: response.Message,
          });

        }
        this.createTicketDialog = false;

      }).catch((error) => {
        console.log(error);
        this.isSubmitting = false;
      });
    },
  },
  watch: {},
  components: {},
}

</script>

<style>
.support .support-header h3 {
  margin: 15px 0px;
}


.support .signfast-default-btn {
  height: auto !important;
}

</style>
