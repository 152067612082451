import '@babel/polyfill';

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import VueTextareaAutosize from 'vue-textarea-autosize';

import ElLoading from 'element-ui/lib/loading';
// import ElementUI from 'element-ui';
// import VueEvents from 'vue-events';
// import locale from 'element-ui/lib/locale/lang/en';
// import VueSimplemde from 'vue-simplemde';
import 'element-ui/lib/theme-chalk/index.css';
/* Sweetalert2 */
import Swal from 'sweetalert2';
import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure you are using css-loader
import Vuetify from 'vuetify';
import 'vue-croppa/dist/vue-croppa.css';
import Croppa from 'vue-croppa';
// Frontend [Jitendra.p]
import moment from 'moment';
import MasterLayout from '../../Modules/Organization/Assets/js/components/Frontend/Layout/masterLayout';
import Navigation from '../../Modules/Organization/Assets/js/components/Frontend/Layout/navigation';
import Footer from '../../Modules/Organization/Assets/js/components/Frontend/Layout/footer';
import UsersRoutes from "../../Modules/Organization/Assets/js/routes/frontend/UsersRoutes";
import SupportRoutes from "../../Modules/Supports/Assets/js/routes/frontend/SupportFrontRoutes";
// support Frontend Route

require('./bootstrap');
// require("signature_pad");
window.Swal = Swal;

const toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    allowOutsideClick: false,
    // width: 'auto',
});

window.toast = toast;

/* End Sweetalert2 */

// added by     ::  JEET DUMS
// import VueDragResize from 'vue-drag-resize'
// import BootstrapVue from 'bootstrap-vue'

// import colors from 'vuetify/es5/util/colors'

// import Form from 'form-backend-validation';
// window.Form = Form;
// import Sortable from 'vue-sortable'

// Vue.use(ElementUI);
Vue.use(ElLoading);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(VueTextareaAutosize);
// Vue.use(require('vue-shortkey'), {prevent: ['input', 'textarea']});


// JEET DUMS
// Vue.use(BootstrapVue);
// Vue.use(Sortable);
// import 'vuetify/dist/vuetify.min.css';

Vue.use(
    Vuetify, {
        theme: {
            primary: '#0c59c7',
            secondary: '#0c59c7',
            accent: '#0c59c7',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
        },
    });

Vue.use(Croppa);

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'vuetify/dist/vuetify.css' // Ensure you are using css-loader


// Vue.use(VueEvents);
// Vue.use(VueSimplemde);
require('./mixins');

// Vue.component('ckeditor', require('../../Modules/Core/Assets/js/components/CkEditor.vue'));
// Vue.component('DeleteButton', require('../../Modules/Core/Assets/js/components/DeleteComponent.vue'));
// Vue.component('EditButton', require('../../Modules/Core/Assets/js/components/EditButtonComponent.vue'));
Vue.component('pagination', require('laravel-vue-pagination'));
// // Vue.component('TagsInput', require('../../Modules/Tag/Assets/js/components/TagInput.vue'));
// // Vue.component('SingleMedia', require('../../Modules/Media/Assets/js/components/SingleMedia.vue'));
// // Vue.component('MediaManager', require('../../Modules/Media/Assets/js/components/MediaManager.vue'));
// Vue.component('editor-component', require('../../Modules/Organization/Assets/js/components/Frontend/documents/EditorComponent.vue'));
// Vue.component('organization-users-login', require('../../Modules/Organization/Assets/js/components/Frontend/users/loginComponent.vue'));
// Vue.component('organization-users-register', require('../../Modules/Organization/Assets/js/components/Frontend/users/registerComponent.vue'));
// // Vue.component('subscription-list', require('../../Modules/Organization/Assets/js/components/Frontend/subscriptions/listComponent.vue'));
Vue.component('master-layout', MasterLayout);
Vue.component('navigation', Navigation);
Vue.component('custom-footer', Footer);
// Vue.component("editor", require("../../Modules/Organization/Assets/js/components/Frontend/documents/EditorComponent"));

Vue.filter('capitalize', (value) => {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('cardYearFromat', (value) => {
    if (!value) return '';
    value = value.toString();
    return value.substring(2, 4);
});

Vue.filter('day', (value) => {
    if (value) {
        return moment(String(value)).format('Do');
    }
});

Vue.filter('shortMonth', (value) => {
    if (value) {
        return moment(String(value)).format('MMM');
    }
});

Vue.filter('fullMonth', (value) => {
    if (value) {
        return moment(String(value)).format('MMMM');
    }
});

Vue.filter('dayWithShortMonth', (value) => {
    if (value) {
        return moment(String(value)).format('Do MMM');
    }
});

Vue.filter('dayWithFullMonth', (value) => {
    if (value) {
        return moment(String(value)).format('Do MMMM');
    }
});

Vue.filter('formatDate', (value) => {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY');
    }
});

Vue.filter('formatDateTime', (value) => {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm');
    }
});

// JEET DUMS
// Vue.component('vue-drag-resize', VueDragResize);
// Vue.component('vue-draggable-resizable', VueDraggableResizable) // not in use


// const currentLocale = window.AsgardCMS.currentLocale;
// // const adminPrefix = window.AsgardCMS.adminPrefix;
//
// function makeBaseUrl() {
//     // console.log(currentLocale);
//     if (window.AsgardCMS.hideDefaultLocaleInURL == 1) {
//         return "/";
//     }
//     return `${currentLocale}/`;
// }


const router = new VueRouter({
        mode: 'history',
        // base: makeBaseUrl(),
        routes: [
            ...UsersRoutes,
            ...SupportRoutes,
        ],
    }),
    /* messages = {
        [currentLocale]: window.AsgardCMS.translations,
    },
    i18n = new VueI18n({
        locale: currentLocale,
        messages,
    }), */
    app = new Vue({
        el: '#app',
        router,
        data: {
            // return {
            // $apibaseurl: 'http://leadcityesign.vlab/api'
            // }
        },
    });

// const ApiBaseUrl = 'http://leadcityesign.vlab/api';
window.axios.defaults.baseURL = 'http://signfast.vlab/api';

window.axios.interceptors.response.use(null, (error) => {
    if (error.response === undefined
    ) {
        console.log(error);
        return;
    }
    if (error.response.status === 403) {
        app.$notify.error({
            title: app.$t('core.unauthorized'),
            message: app.$t('core.unauthorized-access'),
        });
        window.location = route('dashboard.index');
    }
    if (error.response.status === 401) {
        app.$notify.error({
            title: app.$t('core.unauthenticated'),
            message: app.$t('core.unauthenticated-message'),
        });
        window.location = route('login');
    }
    return Promise.reject(error);
});
