import Form from 'form-backend-validation';

export default {
    data() {
        return {
            form: new Form(),
            uploadForm: {
                documents: {},
                documentUrls: {},
                with_fields: "no",
                type: "",
                option: "",
                role: "",
                files: "",
                filename: ""
            },
            redirectUri: route('frontend.users.dashboard').template,
            pickerApiLoaded: false,
            scope: "https://www.googleapis.com/auth/drive.file",
            oauthToken: null
        };
    },
    methods: {
        /*checkFieldPosition : function(attributes){
            var pageNumber = attributes.pageNumber;
            var docx = attributes.docx;
            var docy = attributes.docy;
            var fieldWidth = attributes.fieldWidth;
            var fieldHeight = attributes.fieldHeight;
            var field_id = attributes.id;

            if (docx+ fieldWidth > $('#documentlist li').eq(pageNumber-1).find('div:first-child').outerWidth()) {
                docx = $('#documentlist li').eq(pageNumber-1).find('div:first-child').outerWidth() - fieldWidth;
            }

            if(docy + fieldHeight > this.pdf_pages[pageNumber-1].new_height){
                docy=this.pdf_pages[pageNumber-1].new_height-fieldHeight;
            }

            if(docx < 0)
            {
                docx = 0;
            }

            if(docy < 0)
            {
                docy = 0;
            }

            var detectFieldExistOnPlace = this.tempCollection.filter({page_number:pageNumber,y: docy,x: docx});
            var detectFieldExistOnPlace2 = this.tempCollection.find((field) => {
                var x2 = field.x+(field.w/2);
                var y2 = field.y+(field.h/2);
                var docx2 = docx+(fieldWidth/2);
                var docy2 = docy+(fieldHeight/2);

                var fieldWidthX=field.x + field.w;
                var fieldHeightY=field.y + field.h;
                var documentWidthX=docx + fieldWidth;
                var documentHeightY=docy + fieldHeight;
                var idCond = true;
                if(attributes.id != undefined)
                {
                    idCond =field.id!=attributes.id;
                }

                return idCond && field.page_number == pageNumber && (
                    (
                        (docy >= field.y && docy <= y2) &&
                        (docx >= field.x && docx <= x2)
                    ) ||
                    (
                        (docy >= field.y && docy <= y2) &&
                        (docx2 >= field.x && docx2 <= x2)
                    ) ||
                    (
                        (docy2 >= field.y && docy2 <= y2) &&
                        (docx >= field.x && docx <= x2)
                    ) ||
                    (
                        (docy2 >= field.y && docy2 <= y2) &&
                        (docx2 >= field.x && docx2 <= x2)
                    ) ||
                    // (fieldWidth < field.w && docy2 >= field.y)
                    (
                        fieldWidth > field.w &&
                        (
                            (x2 <= (docx+fieldWidth)  && field.x >= docx) &&
                            (y2 <= (docy+fieldHeight) && field.y >=docy )
                        )
                    ) ||
                    (
                        fieldWidth > field.w &&
                        (
                            (x2 <= docx2 && docx2 <= (x2+(field.w / 2))) &&
                            (y2 <= docy2 && docy2 <= (y2+(field.h / 2)))
                        )
                    )
                    ||
                    (
                        fieldWidth > field.w &&
                        (
                            (field.y <= docy && docy <= (field.y+field.h)) &&
                            (docx <= field.x && (docx+fieldWidth) >= (field.x+field.w) )
                        )
                    )||
                    (
                        fieldWidth > field.w &&
                        (
                            (field.y <= docy && docy <= (field.y+field.h)) && //new field's height is involved within existing element
                            (docx <= field.x && (docx+fieldWidth) >= (field.x+field.w) ) //new field's width overlapped existing element
                        )
                    ) || //Check if new field is completely involved within existing field. eg checkbox
                    (
                        fieldWidth < field.w &&
                        (
                            (fieldWidthX >= documentWidthX && fieldHeightY >= documentHeightY) &&
                            (field.x <= docx && field.y <= docy)
                        )
                    ) || //Check if new field's first half is overlapping an existing field
                    (
                        fieldWidth < field.w &&
                        (
                            (field.x <= docx  &&  docx2 <= fieldWidthX) &&
                            (field.y <= docy && docy2 <= fieldHeightY)
                        )
                    )

                    || //Check if new field's first half is overlapping an existing field
                    (
                        fieldWidth < field.w &&
                        (
                            (field.x <= docx  &&  docx2 <= fieldWidthX) &&
                            (field.y <= docy && docy2 <= fieldHeightY)
                        )
                    )
                );

                /!*console.log('x2',x2, 'y2', y2, 'docx2',docx2,'docy2',docy2);
                return field.page_number == pageNumber && (((docy >= field.y && docy <= y2) && (docx >= field.x && docx <= x2)) || ((docy >= field.y && docy <= y2) && (docx2 >= field.x && docx2 <= x2)) || ((docy2 >= field.y && docy2 <= y2) && (docx >= field.x && docx <= x2)) || ((docy2 >= field.y && docy2 <= y2) && (docx2 >= field.x && docx2 <= x2)) ||  (fieldWidth < field.w && docy2 >= field.y));*!/
            });
            if(field_id!=undefined)
            {
                if(detectFieldExistOnPlace2!=undefined)
                {
                    detectFieldExistOnPlace2 = detectFieldExistOnPlace2.id == field_id ? undefined : true;
                }
                if(detectFieldExistOnPlace.models.length > 0)
                {
                    detectFieldExistOnPlace.models =detectFieldExistOnPlace.models[0].id == field_id ? [] : detectFieldExistOnPlace.models;
                }
            }
            var response = {
                status:false,
                docx:docx,
                docy:docy,
            }
            console.log('detectFieldExistOnPlace2',detectFieldExistOnPlace2);
            if(detectFieldExistOnPlace.models.length > 0 || detectFieldExistOnPlace2!=undefined){
                response.status=false;
                toast.fire({
                    type: 'warning',
                    title: 'Unable to place or duplicate tag that will substantially overlap existing tag'
                });
            }
            else
            {
                response.status=true;
            }
            return response;
        },*/
        checkFieldPosition : function(attributes){
            var pageNumber = attributes.pageNumber;
            var docx = attributes.docx;
            var docy = attributes.docy;
            var fieldWidth = attributes.fieldWidth;
            var fieldHeight = attributes.fieldHeight;
            var field_id = attributes.id;

            if (docx+ fieldWidth > $('#documentlist li').eq(pageNumber-1).find('div:first-child').outerWidth()) {
                docx = $('#documentlist li').eq(pageNumber-1).find('div:first-child').outerWidth() - fieldWidth;
            }
            if(docy + fieldHeight > this.document.pdf_pages[pageNumber-1].new_height){
                docy=this.document.pdf_pages[pageNumber-1].new_height-fieldHeight;
            }
            if(docx < 0) {
                docx = 0;
            }
            if(docy < 0) {
                docy = 0;
            }

            // var detectFieldExistOnPlace = this.tempCollection.filter({page_number:pageNumber,y: docy,x: docx});
            // var detectFieldExistOnPlace2 = this.tempCollection.find((field) => {
            //     var x2 = field.x+(field.w/2);
            //     var y2 = field.y+(field.h/2);
            //     var docx2 = docx+(fieldWidth/2);
            //     var docy2 = docy+(fieldHeight/2);
            //
            //     var fieldWidthX=field.x + field.w;
            //     var fieldHeightY=field.y + field.h;
            //     var documentWidthX=docx + fieldWidth;
            //     var documentHeightY=docy + fieldHeight;
            //     var idCond = true;
            //     if(attributes.id != undefined)
            //     {
            //         idCond =field.id!=attributes.id;
            //     }
            //
            //     var con1 = (
            //         (docy >= field.y && docy <= y2) &&
            //         (docx >= field.x && docx <= x2)
            //     );
            //     var con2 = (
            //         (docy >= field.y && docy <= y2) &&
            //         (docx2 >= field.x && docx2 <= x2)
            //     );
            //     var con3 = (
            //         (docy2 >= field.y && docy2 <= y2) &&
            //         (docx >= field.x && docx <= x2)
            //     );
            //     var con4 = (
            //         (docy2 >= field.y && docy2 <= y2) &&
            //         (docx2 >= field.x && docx2 <= x2)
            //     );
            //     var con5 = (
            //         fieldWidth > field.w &&
            //         (
            //             (x2 <= (docx+fieldWidth)  && field.x >= docx) &&
            //             (y2 <= (docy+fieldHeight) && field.y >=docy )
            //         )
            //     );
            //     var con6 = (
            //         fieldWidth > field.w &&
            //         (
            //             (x2 <= docx2 && docx2 <= (x2+(field.w / 2))) &&
            //             (y2 <= docy2 && docy2 <= (y2+(field.h / 2)))
            //         )
            //     );
            //     var con7 = (
            //         fieldWidth > field.w &&
            //         (
            //             (field.y <= docy && docy <= (field.y+field.h)) &&
            //             (docx <= field.x && (docx+fieldWidth) >= (field.x+field.w) )
            //         )
            //     );
            //     var con8 = (
            //         fieldWidth > field.w &&
            //         (
            //             (field.y <= docy && docy <= (field.y+field.h)) && //new field's height is involved within existing element
            //             (docx <= field.x && (docx+fieldWidth) >= (field.x+field.w) ) //new field's width overlapped existing element
            //         )
            //     );
            //     var con9 = (fieldWidth < field.w &&
            //         (
            //             (fieldWidthX >= documentWidthX && fieldHeightY >= documentHeightY) &&
            //             (field.x <= docx && field.y <= docy)
            //         )
            //     );
            //     var con10 = (fieldWidth < field.w &&
            //         (
            //             (field.x <= docx  &&  docx2 <= fieldWidthX) &&
            //             (field.y <= docy && docy2 <= fieldHeightY)
            //         )
            //     );
            //     var con11 = (fieldWidth < field.w &&
            //         (
            //             (field.x <= docx  &&  docx2 <= fieldWidthX) &&
            //             (field.y <= docy && docy2 <= fieldHeightY)
            //         )
            //     );
            //     var con12 = ( //prevent new field's top middle point
            //
            //         (
            //             (field.x <= docx2  &&  docx2 <= fieldWidthX) &&
            //             (field.y <= docy2 && docy2 <= fieldHeightY)
            //         )
            //     );
            //     /*if(con1) {
            //         console.log('con1', con1);
            //     }
            //     if(con2) {
            //         console.log('con2', con2);
            //     }
            //     if(con3) {
            //         console.log('con3', con3);
            //     }
            //     if(con4) {
            //         console.log('con4', con4);
            //     }
            //     if(con5) {
            //         console.log('con5', con5);
            //     }
            //     if(con6) {
            //         console.log('con6', con6);
            //     }
            //     if(con7) {
            //         console.log('con7', con7);
            //     }
            //     if(con7) {
            //         console.log('con7', con7);
            //     }
            //     if(con8) {
            //         console.log('con8', con8);
            //     }
            //     if(con9) {
            //         console.log('con9', con9);
            //     }
            //     if(con10) {
            //         console.log('con10', con10);
            //     }
            //     if(con11) {
            //         console.log('con11', con11);
            //     }*/
            //     return idCond && field.page_number == pageNumber && (
            //         con1 ||
            //         con2 ||
            //         con3 ||
            //         con4 || // (fieldWidth < field.w && docy2 >= field.y)
            //         con5 ||
            //         con6 ||
            //         con7 ||
            //         con8 || //Check if new field is completely involved within existing field. eg checkbox
            //         con9 || //Check if new field's first half is overlapping an existing field
            //         con10 || //Check if new field's first half is overlapping an existing field
            //         con11 ||
            //         con12
            //     );
            //
            //     /*console.log('x2',x2, 'y2', y2, 'docx2',docx2,'docy2',docy2);
            //     return field.page_number == pageNumber && (((docy >= field.y && docy <= y2) && (docx >= field.x && docx <= x2)) || ((docy >= field.y && docy <= y2) && (docx2 >= field.x && docx2 <= x2)) || ((docy2 >= field.y && docy2 <= y2) && (docx >= field.x && docx <= x2)) || ((docy2 >= field.y && docy2 <= y2) && (docx2 >= field.x && docx2 <= x2)) ||  (fieldWidth < field.w && docy2 >= field.y));*/
            // });
            // console.log('checkFieldPosition >> detectFieldExistOnPlace:: ', detectFieldExistOnPlace, 'detectFieldExistOnPlace2:: ', detectFieldExistOnPlace2);
            /*if(field_id!=undefined) {
                if(detectFieldExistOnPlace2!=undefined)
                {
                    detectFieldExistOnPlace2 = detectFieldExistOnPlace2.id == field_id ? undefined : true;
                }
                if(detectFieldExistOnPlace.models.length > 0)
                {
                    detectFieldExistOnPlace.models =detectFieldExistOnPlace.models[0].id == field_id ? [] : detectFieldExistOnPlace.models;
                }
            }*/
            var response = {
                status:true,
                docx:docx,
                docy:docy,
            }
            /*if (detectFieldExistOnPlace.models.length > 0 || detectFieldExistOnPlace2 != undefined) {
                console.log('checkFieldPosition > detectFieldExistOnPlace2464646',detectFieldExistOnPlace2);
                response.status = true; // response.status = false;
                toast.fire({
                    type: 'warning',
                    title: 'Unable to place or duplicate tag that will substantially overlap existing tag!'
                });
                console.log('~> Unable to place or duplicate tag that will substantially overlap existing tag!');
            } else {
                response.status = true;
            }*/
            return response;
        },

        uploadClickEvent(uploadType) {
            localStorage.removeItem('DocumentUploadTypeApplied');
            localStorage.setItem('DocumentUploadType', uploadType);
        },
        upload(uploadType) {
            this.uploadForm.option = 'assign_to_master';

            const loading = this.$loading({
                lock: true,
                text: 'Uploading Document...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            if (uploadType == 'uploadAndAddSigners') {
                var uploadedFiles = this.$refs.documentfile1.files;
                // console.log(uploadedFiles);
            }
            else {
                var uploadedFiles = this.$refs.documentfile2.files;
                // console.log(uploadedFiles);
            }

            if (uploadedFiles.length > 5) {
                toast.fire({
                    type: 'error',
                    title: 'You can not upload more then 5 documents.',
                });
                this.loading = false;
                loading.close();
                return false;
            }

            var totalSize = 0;

            for (var i = 0; i < uploadedFiles.length; i++) {
                this.uploadForm['documents[' + i + ']'] = uploadedFiles[i];
                const currentFileSize = (uploadedFiles[i].size / (1024 * 1024)).toFixed(2);
                totalSize += parseFloat(currentFileSize);
            }
            // console.log(totalSize);

            if (totalSize > window.AsgardCMS.upload_max_filesize) {
                toast.fire({type: 'error', title: `You can not upload more then ${window.AsgardCMS.upload_max_filesize} MB file size.`});
                this.loading = false;
                loading.close();
                return false;
            }
            this.uploadDocuments(this.uploadForm);
        },
        uploadDocuments(uploadForm) {

            const loading = this.$loading({
                lock: true,
                text: 'Uploading Document...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            this.form = new Form(this.uploadForm);
            this.form.post(route('api.users.web.documents.store')).then((response) => {

                this.isSubmitting = false;
                if (response.Success) {
                    window.location.href = route('frontend.users.document.edit', response.Data.display_id)
                } else {
                    if (response.Data != undefined && response.Data.is_warning == true) {
                        toast.fire({
                            type: 'warning',
                            title: response.Message,
                        });
                    } else {
                        toast.fire({
                            type: 'error',
                            title: response.Message,
                            timer: 6000
                        });
                    }
                    this.$refs.documentfile1.value = "";
                    this.$refs.documentfile2.value = "";
                    loading.close();
                }
                // this.loading = false;
            }).catch((error) => {
                console.log(error);
                // this.loading = false;
                // toast.fire({
                //     type: 'error',
                //     title: response.Message,
                //     timer: 6000
                // });

                loading.close();
            });
        },
        checkPdfFields(uploadType) { // not in used as per new flow      :: JEET DUMS - 30-07-2019
            this.loading = true;

            if (uploadType == 'uploadAndAddSigners') {
                var uploadedFiles = this.$refs.documentfile1.files;
            } else {
                var uploadedFiles = this.$refs.documentfile2.files;
            }
            if (uploadedFiles.length > 5) {
                toast.fire({
                    type: 'error',
                    title: 'You can not upload more then 5 documents.',
                });
                this.loading = false;
                loading.close();
                return false;
            }
            var otherType = false;
            var totalSize = 0;

            for (var i = 0; i < uploadedFiles.length; i++) {
                this.uploadForm['documents[' + i + ']'] = uploadedFiles[i];
                // console.log(uploadedFiles[i]);
                if (uploadedFiles[i].type != "application/pdf") {
                    otherType = true;
                }
                const currentFileSize = (uploadedFiles[i].size / (1024 * 1024)).toFixed(2);
                totalSize += parseFloat(currentFileSize);
            }
            // console.log(totalSize);

            if (totalSize > window.AsgardCMS.upload_max_filesize) {
                toast.fire({
                    type: 'error',
                    title: `You can not upload more then ${window.AsgardCMS.upload_max_filesize} MB file size.`
                });
                this.loading = false;
                return false;
            }


            if (otherType == true) {
                this.uploadForm.option = "keep_form_data";
                this.uploadForm.with_fields = "no";
                this.upload();
            } else if (uploadType == "uploadAndApplyOverlay") {
                this.uploadForm.option = "keep_form_data";
                this.uploadForm.with_fields = "no";
                this.upload();
            } else {
                const loading = this.$loading({
                    lock: true,
                    text: 'Uploading Document...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.form = new Form(this.uploadForm);
                this.form.post(route('api.users.documents.checkpdffields')).then((response) => {
                    this.isSubmitting = false;
                    loading.close();
                    if (response.Success) {

                        this.uploadForm.files = response.Files;
                        this.uploadForm.filename = response.filename;
                        if (response.Field) {
                            this.uploadForm.with_fields = "yes";
                            this.filedImportDialog = true;
                            this.fetchedOrganizationRole();
                            return true;
                        } else {
                            this.uploadForm.with_fields = "no";
                            this.upload();
                        }
                    } else {

                        if (response.Data.is_warning != undefined && response.Data.is_warning == true) {
                            toast.fire({
                                type: 'warning',
                                title: response.Message,
                            });
                        } else {
                            toast.fire({
                                type: 'error',
                                title: response.Message,
                            });
                        }
                    }
                    this.$refs.documentfile1.value = "";
                    this.$refs.documentfile2.value = "";
                    this.loading = false;
                    loading.close();
                }).catch((error) => {
                    // console.log(error);
                    this.isSubmitting = false;
                    loading.close();
                    if (error.response.status === 422) {
                        if (error.response.data.message == "The given data was invalid.") {
                            toast.fire({
                                type: 'error',
                                title: "The given file was invalid.",
                            });
                        }
                    }
                });
            }
        },

        // Dropbox
        dropboxIconClicked(uploadType) {

            this.uploadClickEvent(uploadType);
            const obj = this;
            let options = {
                success: async files => {

                    // console.log(files);
                    let attachments = [];
                    for (let i = 0; i < files.length; i++) {

                        // console.log(files[i]);
                        let attachment = {};
                        attachment._id = files[i].id;
                        attachment.title = files[i].name;
                        attachment.size = files[i].bytes;
                        attachment.iconURL = files[i].icon;
                        attachment.link = files[i].link;
                        // attachment.extension = `${files[i].name.split(".")[1]}`;
                        attachment.extension = files[i].name.substring(files[i].name.lastIndexOf(".")+1);
                        attachments.push(attachment);

                    }
                    obj.uploadForm.documentUrls = attachments;
                    obj.uploadForm.type = "dropbox";
                    obj.uploadDocuments(obj.uploadForm);
                    return false;
                },

                cancel: function () {
                },

                linkType: "direct",

                multiselect: true,

                extensions: [".pdf", ".doc", ".docx", ".png", ".jpg", "jpeg", ".txt"],

                folderselect: false,

                sizeLimit: 102400000
            };
            Dropbox.choose(options);

        },

        //Google Drive  start
        async driveIconClicked(uploadType) {

            this.uploadClickEvent(uploadType);
            // console.log("Clicked");
            const googleDrive = this.activeUser.googleDrive;
            // console.log(googleDrive.appKey);
            await gapi.load("auth2", () => {
                // console.log("Auth2 Loaded");
                // console.log(googleDrive.appKey);
                gapi.auth2.authorize(
                    {
                        client_id:googleDrive.clientId,
                        scope: this.scope,
                        immediate: false
                    },
                    this.handleAuthResult
                );
            });
            gapi.load("picker", () => {
                // console.log("Picker Loaded");
                this.pickerApiLoaded = true;
                this.createPicker();
            });
        },
        handleAuthResult(authResult) {
            // console.log("Handle Auth result", authResult);
            if (authResult && !authResult.error) {
                this.oauthToken = authResult.access_token;
                this.createPicker();
            }
        },
        createPicker() {
            const googleDrive = this.activeUser.googleDrive;

            if (this.pickerApiLoaded && this.oauthToken!==null && this.oauthToken!==undefined) {
                // console.log("oauthToken: ", this.oauthToken);
                // console.log("this.developerKey: ", this.developerKey);
                console.log("googleDrive: ", googleDrive);
                let view = new google.picker.View(google.picker.ViewId.DOCS);
                view.setMimeTypes('application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/gif,image/png,image/jpg.image/jpeg');

                var picker = new google.picker.PickerBuilder()
                    .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                    .addView(google.picker.ViewId.DOCS)
                    .addView(view)
                    .setAppId(googleDrive.appId)
                    .setOAuthToken(this.oauthToken)
                    .setDeveloperKey(googleDrive.appKey)
                    .setCallback(this.pickerCallback)
                    .build();
                picker.setVisible(true);
            }
        },
        async pickerCallback(data) {
            console.log("PickerCallback", data);
            const obj = this;
            if (data.docs != undefined && data.docs.length > 0) {
                let attachments = [];
                data.docs.forEach(data => {

                    // googleDrive.files(data.id).get(params,(data)=>{

                    // console.log(data);
                    // });
                    //  return false;
                    let attachment = {};
                    attachment._id = data.id;
                    attachment.title = data.name;
                    attachment.size = data.sizeBytes;
                    attachment.link = data.embedUrl;
                    attachment.extension = `${data.mimeType.split("/").slice(-1)}`;
                    attachment.mimeType = data.mimeType;
                    attachment.oauthToken = this.oauthToken;
                    attachments.push(attachment);
                });

                console.log("attachments",attachments);

                obj.uploadForm.documentUrls = attachments;
                obj.uploadForm.type = "google";
                // console.log("attachments",obj.uploadForm);
                obj.uploadDocuments(obj.uploadForm);
                return false;
            }

        },
        showDetails(data) {
            // console('Google Drive');
            if (data.picked === 'picked') {
                // console.log(data.docs);
            }
        },

        //One Drive
        launchOneDrivePicker(uploadType) {
            // alert(uploadType);
            // this.uploadClickEvent(uploadType);
            const oneDrive = this.activeUser.oneDrive;
            // let stringifyPath=this.$router.stringifyPath;
            // console.log(stringifyPath);
            const obj = this;
            let odOptions = {
                clientId:oneDrive.clientId,
                action: "download",
                multiSelect: true,
                advanced: {
                    redirectUri: obj.redirectUri,
                    queryParameters: "select=id,name,size,file,folder,photo,@microsoft.graph.downloadUrl",
                    filter:"folder,.pdf,.jpg,.jpeg,.png,.gif,.doc,.docx",
                },
                success: function(files) {
                    obj.uploadClickEvent(uploadType);

                    // console.log(files);
                    let attachments = [];
                    files.value.forEach(file => {

                        let attachment = {};
                        attachment._id = file.id
                        attachment.title = file.name;
                        attachment.size = file.size;
                        attachment.iconURL =file['@microsoft.graph.downloadUrl'];
                        attachment.link =file['@microsoft.graph.downloadUrl'];
                        attachment.extension = `${file.name.split(".").slice(-1)}`;
                        attachment.mimeType = file.file.mimeType;
                        attachments.push(attachment);
                    });
                    obj.uploadForm.documentUrls = attachments;
                    obj.uploadForm.type = "onedrive";
                    obj.uploadDocuments(obj.uploadForm);
                    return false;
                    // console.log(files)
                },
                cancel: function(cancel) { console.log(cancel) },
                error: function(error) { console.log(error) }
              }
            OneDrive.open(odOptions);
        },
    },
    mounted() {

        // console.log('DocumentHelper Script start');

        if(this.activeUser.dropBox!=undefined) {
            const dropBoxdata = this.activeUser.dropBox;
            let dropBox = document.createElement("script");
            dropBox.setAttribute(
                "src",
                "https://www.dropbox.com/static/api/2/dropins.js"
            );
            dropBox.setAttribute("id", "dropboxjs");
            dropBox.setAttribute("data-app-key", dropBoxdata.appKey);

            // console.log("dropBox: ", dropBox);
            // console.log("dropBoxdata: ", dropBoxdata);
            document.head.appendChild(dropBox);
        }

        //Google Drive
        let gDrive = document.createElement("script");
        gDrive.setAttribute("type", "text/javascript");
        gDrive.setAttribute("src", "https://apis.google.com/js/api.js");
        document.head.appendChild(gDrive);

        //One Drive
        let OneDrive = document.createElement("script");
        OneDrive.setAttribute("type", "text/javascript");
        OneDrive.setAttribute("src", "https://js.live.net/v7.2/OneDrive.js");
        document.head.appendChild(OneDrive);
        // console.log('DocumentHelper Script End');
    }
};
